import React from 'react'

export default function Partners({partnerRef}) {
  return (
    <div className='bg-white ' ref={partnerRef}>
        <div className='text-center font-bold text-4xl '>Partner Retail Brands</div>
        <div className="flex items-center justify-center ">
          <div className="mt-3 w-32 h-2 bg-customYellow "></div>
        </div>
        <div className='flex items-center justify-center mt-14'>
      <img src="https://static.wixstatic.com/media/42d0da_4e9b473867ef4983b7fe71faba1fa2b1~mv2.png/v1/fill/w_808,h_308,al_c,lg_1,q_85,enc_auto/42d0da_4e9b473867ef4983b7fe71faba1fa2b1~mv2.png" /></div>
    </div>
  )
}
