
import React from 'react';
import video from '../Assests/file.mp4';

const File = () => {
  return (
    <div className="relative mx-auto">
      {/* Video background for larger screens */}
      <div className="fixed inset-0 w-full h-full overflow-hidden z-[-1] hidden sm:block">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute inset-0 bg-local w-full h-full object-cover"
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      
      {/* Static image background for smaller screens */}
      <div className="block sm:hidden w-full h-screen bg-cover bg-center bg-no-repeat" style={{backgroundImage: "linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('https://static.wixstatic.com/media/a9aecfcd0c474899a57da07176a929c4.jpg/v1/fill/w_400,h_958,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/a9aecfcd0c474899a57da07176a929c4.jpg')"}}>
      </div>

      {/* Your content goes here */}
      <div className="relative z-10 p-4 text-white">
        <h1 className="text-4xl">Welcome to My React App</h1>
        {/* Additional content */}
      </div>
    </div>
  );
}

export default File;
