import React from 'react'

export default function Footer() {
  return (
    <div className='bg-white'>
    <div className='md:flex md:p-0 p-5 justify-between  md:px-60'>
      <div className='font-light text-md mb-3'>
      © 2024 by Joy Bharath Enterprises Privated Limited. 
      </div>
      <div className='flex gap-2'>
        <a href=''><img src='https://static.wixstatic.com/media/4057345bcf57474b96976284050c00df.png/v1/fill/w_38,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/4057345bcf57474b96976284050c00df.png'></img></a>
        <a href=''><img src='https://static.wixstatic.com/media/aa0402eb9ba2430d9d0620b59556efca.png/v1/fill/w_38,h_38,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/aa0402eb9ba2430d9d0620b59556efca.png'></img></a>
      </div>
    </div>
    </div>
  )
}