import React from 'react'

export default function Yellow() {
  return (
    <div className='w-full  py-10 h-full  bg-customYellow text-sky-900 flex justify-center items-center'>
    <div className='text-center md:mx-40 grid grid-cols-2  gap-2 md:grid-cols-4 md:gap-3'>
        <div className='md:border-r border-white pr-2 md:pr-0'>
            <span className='md:text-4xl text-3xl font-semibold'>5 million sft</span><br/>
            <span className='md:text-2xl text-xl font-semibold'>Retail space by 2034</span>
        </div>
        <div className='md:border-r border-white pr-2 md:pr-4'>
            <span className='md:text-4xl text-3xl  font-semibold'>206</span><br/>
            <span className='md:text-2xl text-xl  font-semibold'>International Retail Brands</span> 
        </div>
        <div className='md:border-r border-white  mt-3 sm:mt-0 pr-2 md:pr-4'>
            <span className='md:text-4xl text-3xl  font-semibold'>60 million</span><br/>
            <span className='md:text-2xl text-xl font-semibold'>Expected customers by 2034</span>
        </div>
        <div className='md:border-r border-white mt-3 sm:mt-0 pr-2 md:pr-4'>
            <span className='md:text-4xl text-3xl  font-semibold'>72</span><br/>
            <span className='md:text-2xl text-xl  font-semibold'>Locations by 2034</span>
        </div>
    </div>
</div>
  )
}