import React, { useState, useEffect, useRef } from 'react';
import logo from '../Assests/logo_updated.png';

const NavBar = ({ servicesRef, aboutRef, contactRef, partnerRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const scrollToRef = (ref) => {
    if (ref && ref.current) {
      const yOffset = -80;
      const y = ref.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      setIsOpen(false); // Close the mobile menu after clicking on a link
    }
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', closeMenu);

    return () => {
      document.removeEventListener('click', closeMenu);
    };
  }, []);

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 md:bg-customBlue bg-customYellow h-20" ref={menuRef}>
      <div className="h-20">
        <div className="flex items-center justify-between h-20">
          <div>
            <a href='#'><img src={logo} href="#" className="bg-customYellow h-20 w-20" alt="logo" /></a>
          </div>
          <div className="hidden md:block">
            <div className="flex items-center font-extralight text-base gap-16 md:mr-9">
              <a href="#" className="text-white hover:text-customYellow font-extralight px-3 py-2 rounded-md text-base focus:outline-none">
                HOME
              </a>
              <a onClick={() => scrollToRef(servicesRef)} className="text-white hover:text-customYellow font-extralight px-3 py-2 rounded-md text-base focus:outline-none">
                PROJECTS
              </a>
              <a onClick={() => scrollToRef(aboutRef)} className="text-white hover:text-customYellow font-extralight px-3 py-2 rounded-md text-base focus:outline-none">
                ABOUT
              </a>
              {/* <a onClick={() => scrollToRef(partnerRef)} className="text-white hover:text-customYellow font-extralight px-3 py-2 rounded-md text-base focus:outline-none">
                PARTNERS
              </a> */}
              <a onClick={() => scrollToRef(contactRef)} className="text-white hover:text-customYellow font-extralight px-3 py-2 rounded-md text-base focus:outline-none">
                CONTACT
              </a>
            </div>
          </div>
          <div className="flex md:hidden">
            <button onClick={toggleMenu} className="inline-flex items-center justify-center p-2 rounded-md text-white focus:outline-none focus:text-white">
              <svg className={`${isOpen ? 'hidden' : 'block'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className={`${isOpen ? 'block' : 'hidden'} h-6 w-6`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {/* Mobile Menu */}
      <div onClick={() => setIsOpen(false)} className={`${isOpen ? 'block' : 'hidden'} md:hidden bg-customYellow`}>
        <div className="px-2 pt-2 pb-3 text-center sm:px-3">
          <a href='#' className="text-white hover:text-customBlue block px-3 py-2 rounded-md text-base font-extralight focus:outline-none">
            HOME
          </a>
          <a onClick={() => scrollToRef(servicesRef)} className="text-white  hover:text-customBlue block px-3 py-2 rounded-md text-base font-extralight focus:outline-none">
            PROJECTS
          </a>
          <a onClick={() => scrollToRef(aboutRef)} className="text-white hover:text-customBlue block px-3 py-2 rounded-md text-base font-extralight focus:outline-none">
            ABOUT
          </a>
          {/* <a onClick={() => scrollToRef(partnerRef)} className="text-white hover:text-customBlue block px-3 py-2 rounded-md text-base font-extralight focus:outline-none">
            PARTNERS
          </a> */}
          <a onClick={() => scrollToRef(contactRef)} className="text-white hover:text-customBlue block px-3 py-2 rounded-md text-base font-extralight focus:outline-none">
            CONTACT
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
