import React, { useState} from 'react';
import Axios from 'axios';

export default function Contact({ contactRef }) {

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const url = "https://www.joybharath.com/joy.php";
  const [data, setData] = useState({
    fname: "",
    lname: "",
    mobile: "",
    email: "",
    mobile: "",
    message: ""
  });

  function submitForm(e) {
    e.preventDefault();

    setIsSubmitting(true);

    Axios.post(url, {
      fname: data.fname,
      lname: data.lname,
      email: data.email,
      mobile: data.mobile,
      message: data.message
    })
      .then(res => {
        console.log(res.data);
        setSuccessMessage("We will contact you soon..! Thank You");
      
        setErrorMessage("");
        // Clear form fields after successful submission
        setData({ fname: "", lname: "", email: "", mobile: "", message: "" });
        setInterval(() => {
          setSuccessMessage()
        }, 3000);
      })
      .catch(error => {
        console.error("Error:", error);
        setErrorMessage("An error occurred. Please try again later.");
        setSuccessMessage("");
      })
      .finally(() => {
        // Enable the submit button after the request is completed
        setIsSubmitting(false);
      });
  }

  function handleChange(e) {
    const { id, value } = e.target;
    setData({ ...data, [id]: value });
  }


  return (
    <div className='bg-white mt-5' ref={contactRef}>
      <div className='md:p-10 p-5 '>
        <span className='text-5xl font-semibold text-customBlue flex  justify-center mb-10'>CONTACT</span>
        <div className="flex items-center justify-center ">
          <div className=" w-32 h-2 bg-customYellow -mt-9"></div>
        </div>

        <div className=' md:grid md:grid-cols-2 '>
          <div className="w-full md:px-10 sm:p-3">
            <div className='text-customBlue font-semibold text-2xl'>Contact Us</div>
            
            <div className="flex justify-center items-center">
              {successMessage && (
                <div className="bg-yellow-500 text-white text-center mx-auto p-3 rounded mb-4 inline-block">
                  {successMessage}
                </div>
              )}
              {errorMessage && (
                <div className="bg-yellow-200 text-red-800 p-2 rounded mb-4 inline-block">
                  {errorMessage}
                </div>
              )}
            </div>

            <form  methos="POST" onSubmit={submitForm} className="mt-8">
              <div>
                <label htmlFor="fname" className="block text-sm py-1 text-left font-medium text-gray-700">
                  First Name
                </label>
                <input
                  id="fname"
                  name="fname"
                  type="text"
                  required
                  autoComplete="give-name"
                  className="mt-1 w-full h-8 border  hover:bg-gray-200"
                  value={data.fname}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="lname" className="block text-sm py-1 text-left font-medium text-gray-700">
                  Last Name
                </label>
                <input
                  id="lname"
                  name="lname"
                  type="text"
                  
                  autoComplete="family-name"
                  className="mt-1 w-full h-8 border hover:bg-gray-200"
                  value={data.lname}
                  onChange={handleChange}
                />

              </div>
              <div>
                <label htmlFor="email" className="block text-sm py-1 text-left font-medium text-gray-700">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="mt-1 w-full h-8 border hover:bg-gray-200"
                  value={data.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="mobile" className="block text-sm py-1 text-left font-medium text-gray-700">
                  Mobile
                </label>
                <input
                 id="mobile" 
                 type="text"
                 autoComplete="tel"
                 required
                  className="mt-1 w-full h-8 border  hover:bg-gray-200"
                  value={data.mobile}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="message" className="block text-sm py-1 text-left font-medium text-gray-700">
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  required
                  className="mt-1 w-full border hover:bg-gray-200"
                  value={data.message}
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className='flex justify-end'>
                <button
                  type="submit" disabled={isSubmitting}
                  className="w-36 flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-yellow-500 bg-customBlue hover:bg-yellow-500 hover:text-customBlue"
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
          <div className=' md:grid grid-rows-2 md:gap-2 font-thin  md:ml-24 ml-0'>
          <div>
          <span className='font-semibold text-customBlue text-2xl'>Head Office</span>
          <div className=' text-left'>
            Joy Bharath Enterprises Private Limited, <br />
            4th Floor, Alcazar Plaza, Road Number 1, <br />
            Banjara Hills, Hyderabad - 500038 <br />
            info@chakragroup.in
          </div>
          </div>
          <div className=''>
            <div className='font-semibold text-customBlue text-2xl'>Employment</div>
            <div className=' text-left'>
              To apply for a job with Joy Bharath Enterprises (part of Chakra Group),<br></br> please send a cover letter together with your C.V. to: info@chakragroup.in
            </div>
          </div>
          </div>
          </div>
        </div>
      </div>
    );
}








