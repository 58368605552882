// Services.js
import React from 'react';

function Services({ servicesRef }) {


    return (
        <div ref={servicesRef} className='bg-white mt-auto'>
            <div className=" flex flex-col items-center justify-center ">
                <span className='text-5xl font-semibold text-center text-customBlue'>PROJECTS IN PROGRESS</span>
                <div className='bg-yellow-500 w-24 h-2 mt-8 flex items-center justify-center'></div>
            </div>

            <div className={`grid md:grid-cols-3 p-2 gap-4 mt-8 xl:px-64 `}>
                {/* Your service items */}
                <div className='flex flex-col items-center bg-gray-100'>
                    <img className="w-full h-30 object-cover" src="https://static.wixstatic.com/media/42d0da_d99e25f5bf2143b581c798ad0d83c5f2~mv2.jpg/v1/fill/w_375,h_281,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/NSP%20Mall.jpg" alt="Preconstruction Image" />
                    <div className="mt-2">
                        <h3 className="text-blue-500 text-center text-2xl font-semibold ml-4 mt-4">Narsapuram Miniplex</h3>
                        <h4 className='font-extralight text-center'>Launch 2025</h4>

                        <p className='text-center p-4 font-light'>
                        Located in one of the richest towns in Andhra Pradesh, Narsapuram, the mall has a sprawling 1.10 Lakh Sft., featuring more than 11 retail outlets, an four-screen theatre, with children's play space. It is truly a one-stop shop and has something for everyone.
                        </p>
                    </div>
                </div>

                <div className='flex flex-col items-center bg-gray-100'>
                    <img className="w-full h-30 object-cover" src="https://static.wixstatic.com/media/42d0da_c37fee9febe4439987e36b5a2a4dc7fd~mv2.jpg/v1/fill/w_375,h_281,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Vikarabad%20Mall.jpg" alt="Architecture Modelling Image" />
                    <div className="mt-2">
                        <h3 className="text-blue-500 text-center text-2xl font-semibold ml-4 mt-4">Architecture Modelling</h3>
                        <h4 className='font-extralight text-center'>Launch 2025</h4>
                        <p className='text-center p-4 font-light'>
                        Located in Bhupalpally, one of the fastest growing towns in Telangana, the Mall features 60 thousand Sft of pure opulence, with 6 retail brands from food joints to clothes and a 3 screen theatre to keep you entertained.  
                        </p>
                    </div>
                </div>

                <div className='flex flex-col items-center bg-gray-100'>
                    <img className="w-full h-30 object-cover" src="https://static.wixstatic.com/media/42d0da_4cdacf1fdb8349b79770c0b8703f0337~mv2.jpg/v1/fill/w_375,h_281,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/Vikarabad%20Miniplex.jpg" alt="Construction Management Image" />
                    <div className="mt-2">
                        <h3 className="text-blue-500 text-center text-2xl font-semibold ml-4 mt-4">Construction Management</h3>
                        <h4 className='font-extralight text-center'>Launch 2025</h4>
                        <p className='text-center p-4 font-light'>
                        Located in Vikarabad, very close to Hyderabad, the mall has a built-up area of 60 thousand Sft with 6 retail brands and 3 screen theatres. This mall is ideally located with many higher education institutions surrounding it. 
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
