// About.js
import React from 'react';
import aboutimg from '../Assests/aboutimg.png';

export default function About({ aboutRef }) {
    return (
        <div className='grid md:grid-cols-2 bg-white' ref={aboutRef}>
            <div className="md:order-2">
                <img src={aboutimg} alt="About" style={{ width: '100%' }} />
            </div>
            <div className='bg-gray-100 p-3 '>
                <div className='xl:ml-40'>
                    <span className=' text-5xl font-semibold text-customBlue md:order-1'>ABOUT</span>
                    <div className="mt-3 w-24 h-2 bg-customYellow absolute"></div>
                    <div className='mt-7 font-light leading-7'>
                    At Joy Bharath, we take great pride in our uncompromising integrity in customer engagement and quality assurance, and we have made it our core mission to provide real-estate development,management, and Entertainment services of the highest calibre.

                    </div>
                </div>
            </div>
           
        </div>
    );
}
